import { ProductShippingCardStackProps } from '~/components/ProductShippingCardStack/ProductShippingCardStack.types'
import { ShiipDeliverProvider } from '~/providers/ShiipDeliverProvider'
import CardStack from '../CardStack/CardStack'
import { ProductClickAndCollectStatusCard } from '../ProductClickAndCollectStatusCard'
import { ProductDeliverToPostcodeStatusCard } from '../ProductDeliverToPostcodeStatusCard'

export const ProductShippingCardStack = ({
  storeAvailabilityStatus,
  sku,
}: ProductShippingCardStackProps) => {
  return (
    <CardStack>
      <ShiipDeliverProvider skuList={[sku]}>
        <ProductDeliverToPostcodeStatusCard />
        {storeAvailabilityStatus && (
          <ProductClickAndCollectStatusCard
            {...storeAvailabilityStatus}
            sku={sku}
          />
        )}
      </ShiipDeliverProvider>
    </CardStack>
  )
}
