import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage } from 'react-use'
import dynamic from 'next/dynamic'
import { DeliveryInfoResponse } from 'shared-types'
import { AddressFormProps } from '~/components/AddressConfigureDrawer/AddressConfigureDrawer.types'
import { useSession } from '~/hooks'
import { Client } from '~/customClients/client'
import { ShiipDeliverProviderContext } from './ShiipDeliverProvider.types'

const AddressConfigureDrawer = dynamic(
  async () => {
    const mod = await import('~/components/AddressConfigureDrawer')
    return mod.AddressConfigureDrawer
  },
  { ssr: false }
)

const SPECIAL_ITEM_MESSAGE =
  'Special order items are not available for delivery'

export const ShiipDeliverContext =
  createContext<ShiipDeliverProviderContext>(null)

export const ShiipDeliverProvider = ({
  children,
  skuList,
}: PropsWithChildren<{ skuList: string[] }>) => {
  const { session } = useSession()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [shiipAddress, setShiipAddress] = useLocalStorage<AddressFormProps>(
    'shiip.address',
    null
  )
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfoResponse>()
  const [isLoading, setIsLoading] = useState(false)

  const findShiipDeliveryOptions = useCallback(
    async (address?: AddressFormProps) => {
      const customerAddress = address || shiipAddress
      if (!session || isLoading || !customerAddress) {
        return
      }

      setIsLoading(true)
      const {
        country,
        latitude,
        longitude,
        suburb,
        city,
        state,
        postalCode,
        addressLine1,
      } = customerAddress
      const result = await Client.cart.getDeliveryInfo(
        {
          country,
          latitude,
          longitude,
          suburb,
          city,
          state,
          postalCode,
          addressLine1,
          skuList,
        },
        session
      )
      setDeliveryInfo(result)

      setIsLoading(false)

      if (address) {
        setShiipAddress(address)
      }
    },
    [
      session,
      skuList,
      isLoading,
      shiipAddress,
      setShiipAddress,
      setIsLoading,
      setDeliveryInfo,
    ]
  )

  const handleDrawerOpen = useCallback(() => {
    setDrawerIsOpen(true)
  }, [setDrawerIsOpen])

  const handleDrawerClose = useCallback(() => {
    setDrawerIsOpen(false)
  }, [setDrawerIsOpen])

  const value = useMemo<ShiipDeliverProviderContext>(() => {
    return {
      isLoading,
      shiipAddress,
      deliveryInfo,
      isSpecialItem: deliveryInfo?.message === SPECIAL_ITEM_MESSAGE,
      promptAddressConfigDrawer: handleDrawerOpen,
      findShiipDeliveryOptions,
    }
  }, [
    isLoading,
    shiipAddress,
    deliveryInfo,
    handleDrawerOpen,
    findShiipDeliveryOptions,
  ])

  return (
    <ShiipDeliverContext.Provider value={value}>
      {children}

      {drawerIsOpen && (
        <AddressConfigureDrawer
          open={drawerIsOpen}
          onClose={handleDrawerClose}
        />
      )}
    </ShiipDeliverContext.Provider>
  )
}
