import dynamic from 'next/dynamic'
import { SectionLoadingPlaceholder } from '~/components'

import { Breadcrumbs } from './Breadcrumbs'
import { Header } from './Header'
import { HeroBanner } from './HeroBanner'
import { ProductListingWrapper as ProductListing } from './ProductListing'
import { ProductMain } from './ProductMain'
import { SimpleHeader } from './SimpleHeader'

const MakerHubAllLanding = dynamic(
  async () => {
    const mod = await import('./MakerHubAllLanding')
    return mod.MakerHubAllLandingWrapper
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const FourZeroFour = dynamic(
  async () => {
    const mod = await import('./FourZeroFour')
    return mod.FourZeroFour
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountAddressBook = dynamic(
  async () => {
    const mod = await import('./AccountAddressBook')
    return mod.AccountAddressBook
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountCommunicationPreferences = dynamic(
  async () => {
    const mod = await import('./AccountCommunicationPreferences')
    return mod.AccountCommunicationPreferences
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountLanding = dynamic(
  async () => {
    const mod = await import('./AccountLanding')
    return mod.AccountLanding
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountLoginAndSecurity = dynamic(
  async () => {
    const mod = await import('./AccountLoginAndSecurity')
    return mod.AccountLoginAndSecurity
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountMyRewards = dynamic(
  async () => {
    const mod = await import('./AccountMyRewards')
    return mod.AccountMyRewards
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountOrderDetails = dynamic(
  async () => {
    const mod = await import('./AccountOrderDetails')
    return mod.AccountOrderDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountOrderHistory = dynamic(
  async () => {
    const mod = await import('./AccountOrderHistory')
    return mod.AccountOrderHistory
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountOrderReturn = dynamic(
  async () => {
    const mod = await import('./AccountOrderReturn')
    return mod.AccountOrderReturn
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountPersonalInformation = dynamic(
  async () => {
    const mod = await import('./AccountPersonalInformation')
    return mod.AccountPersonalInformation
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const AccountSavedPaymentMethods = dynamic(
  async () => {
    const mod = await import('./AccountSavedPaymentMethods')
    return mod.AccountSavedPaymentMethods
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ArticleGrid = dynamic(
  async () => {
    const mod = await import('./ArticleGrid')
    return mod.ArticleGrid
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ArticleList = dynamic(
  async () => {
    const mod = await import('./ArticleList')
    return mod.ArticleList
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Cards = dynamic(
  async () => {
    const mod = await import('./Cards')
    return mod.Cards
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const CardsCarousel = dynamic(
  async () => {
    const mod = await import('./CardsCarousel')
    return mod.CardsCarousel
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Checkout = dynamic(
  async () => {
    const mod = await import('./Checkout')
    return mod.Checkout
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Competitions = dynamic(
  async () => {
    const mod = await import('./Competitions')
    return mod.Competitions
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ContactForm = dynamic(
  async () => {
    const mod = await import('./ContactForm')
    return mod.ContactForm
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Footer = dynamic(
  async () => {
    const mod = await import('./Footer')
    return mod.Footer
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const FrequentlyAskedQuestions = dynamic(
  async () => {
    const mod = await import('./FrequentlyAskedQuestions')
    return mod.FrequentlyAskedQuestions
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const FullWidthImage = dynamic(
  async () => {
    const mod = await import('./FullWidthImage')
    return mod.FullWidthImage
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ImageAndTextCards = dynamic(
  async () => {
    const mod = await import('./ImageAndTextCards')
    return mod.ImageAndTextCards
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const InstagramFeed = dynamic(
  async () => {
    const mod = await import('./InstagramFeed')
    return mod.InstagramFeed
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ListDetails = dynamic(
  async () => {
    const mod = await import('./ListDetails')
    return mod.ListDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Login = dynamic(
  async () => {
    const mod = await import('./Login')
    return mod.Login
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubArticleDetail = dynamic(
  async () => {
    const mod = await import('./MakerHubArticleDetail')
    return mod.MakerHubArticleDetail
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubArticleListing = dynamic(
  async () => {
    const mod = await import('./MakerHubArticleListing')
    return mod.MakerHubArticleListingWrapper
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubBlade = dynamic(
  async () => {
    const mod = await import('./MakerHubBlade')
    return mod.MakerHubBlade
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubCreateProjectControls = dynamic(
  async () => {
    const mod = await import('./MakerHubCreateProjectControls')
    return mod.MakerHubCreateProjectControls
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubCreateProjectDetails = dynamic(
  async () => {
    const mod = await import('./MakerHubCreateProjectDetails')
    return mod.MakerHubCreateProjectDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubCreateProjectSteps = dynamic(
  async () => {
    const mod = await import('./MakerHubCreateProjectSteps')
    return mod.MakerHubCreateProjectSteps
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubLanding = dynamic(
  async () => {
    const mod = await import('./MakerHubLanding')
    return mod.MakerHubLanding
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const MakerHubMyProject = dynamic(
  async () => {
    const mod = await import('./MakerHubMyProject')
    return mod.MakerHubMyProject
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const OfferCardsGrid = dynamic(
  async () => {
    const mod = await import('./OfferCardsGrid')
    return mod.OfferCardsGrid
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const OrderConfirmation = dynamic(
  async () => {
    const mod = await import('./OrderConfirmation')
    return mod.OrderConfirmation
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProductDetails = dynamic(
  async () => {
    const mod = await import('./ProductDetails')
    return mod.ProductDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProductMiniCardsCarousel = dynamic(
  async () => {
    const mod = await import('./ProductMiniCardsCarousel')
    return mod.ProductMiniCardsCarousel
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProductsCarousel = dynamic(
  async () => {
    const mod = await import('./ProductsCarousel')
    return mod.ProductsCarousel
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProjectDetails = dynamic(
  async () => {
    const mod = await import('./ProjectDetails')
    return mod.ProjectDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProjectInterestedIn = dynamic(
  async () => {
    const mod = await import('./ProjectInterestedIn')
    return mod.ProjectInterestedIn
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const ProjectSteps = dynamic(
  async () => {
    const mod = await import('./ProjectSteps')
    return mod.ProjectSteps
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const PromoBanner = dynamic(
  async () => {
    const mod = await import('./PromoBanner')
    return mod.PromoBanner
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const PromoCardsGrid = dynamic(
  async () => {
    const mod = await import('./PromoCardsGrid')
    return mod.PromoCardsGrid
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const SecondaryNavigation = dynamic(
  async () => {
    const mod = await import('./SecondaryNavigation')
    return mod.SecondaryNavigation
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const StoreDetails = dynamic(
  async () => {
    const mod = await import('./StoreDetails')
    return mod.StoreDetails
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const StoreLocatorMap = dynamic(
  async () => {
    const mod = await import('./StoreLocatorMap')
    return mod.StoreLocatorMap
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const Text = dynamic(
  async () => {
    const mod = await import('./Text')
    return mod.Text
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const UniqueSellingPointsIcons = dynamic(
  async () => {
    const mod = await import('./UniqueSellingPointsIcons')
    return mod.UniqueSellingPointsIcons
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const VisionSearch = dynamic(
  async () => {
    const mod = await import('./VisionSearch')
    return mod.VisionSearch
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const WorkshopListing = dynamic(
  async () => {
    const mod = await import('./WorkshopListing')
    return mod.WorkshopListing
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const Accordion = dynamic(
  async () => {
    const mod = await import('./Accordion')
    return mod.Accordion
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const ProductComparison = dynamic(
  async () => {
    const mod = await import('./ProductComparison')
    return mod.ProductComparison
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const CustomerLists = dynamic(
  async () => {
    const mod = await import('./CustomerLists')
    return mod.CustomerLists
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const ImageAndText = dynamic(
  async () => {
    const mod = await import('./ImageAndText')
    return mod.ImageAndText
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)
const BenefitCards = dynamic(
  async () => {
    const mod = await import('./BenefitCards')
    return mod.BenefitCards
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const BannerCard = dynamic(
  async () => {
    const mod = await import('./BannerCard')
    return mod.BannerCard
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const RedPepperCatalogue = dynamic(
  async () => {
    const mod = await import('./RedPepperCatalogue')
    return mod.RedPepperCatalogue
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const VideoCarousel = dynamic(
  async () => {
    const mod = await import('./VideoCarousel')
    return mod.VideoCarousel
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

const SimpleTable = dynamic(
  async () => {
    const mod = await import('./SimpleTable')
    return mod.SimpleTable
  },
  {
    loading: () => {
      return <SectionLoadingPlaceholder />
    },
  }
)

export const headerSectionNames = ['Header', 'SimpleHeader']

export const footerSectionNames = ['Footer']

export default {
  AccountCommunicationPreferences,
  AccountRewards: AccountMyRewards,
  Breadcrumbs,
  MakerHubArticleDetail,
  ProductDetails,
  ProjectDetails,
  ProjectInterestedIn,
  ProjectSteps,
  PromoBanner,
  ProductsCarousel,
  ProductMain,
  ProductListing,
  AccountSavedPaymentMethods,
  AccountLoginAndSecurity,
  AccountLanding,
  SecondaryNavigation,
  AccountPersonalInformation,
  AccountAddressBook,
  AccountOrderDetails,
  AccountOrderHistory,
  AccountOrderReturn,
  Login,
  StoreLocatorMap,
  MakerHubAllLanding,
  MakerHubBlade,
  MakerHubArticleListing,
  FullWidthImage,
  StoreDetails,
  Footer,
  HeroBanner,
  UniqueSellingPointsIcons,
  VisionSearch,
  MakerHubLanding,
  ImageAndTextCards,
  InstagramFeed,
  FourZeroFour,
  ProductMiniCardsCarousel,
  WorkshopListing,
  Header,
  SimpleHeader,
  FrequentlyAskedQuestions,
  Text,
  Checkout,
  ArticleList,
  ArticleGrid,
  MakerHubCreateProjectDetails,
  MakerHubCreateProjectControls,
  MakerHubCreateProjectSteps,
  MakerHubMyProject,
  Cards,
  ContactForm,
  CardsCarousel,
  Competitions,
  PromoCardsGrid,
  OrderConfirmation,
  OfferCardsGrid,
  ListDetails,
  Accordion,
  ProductComparison,
  CustomerLists,
  ImageAndText,
  BenefitCards,
  BannerCard,
  RedPepperCatalogue,
  VideoCarousel,
}
